import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import { DataContext } from "../context/DataContext";
import { validation } from "../utils/validation";

const DashboardHelp = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [myJobs, setMyJobs] = useState([]);
  const { register, errors, handleSubmit, reset, control } = useForm();
  const { getjobsList } = useContext(DataContext);

  const fetchJobs = async (accountId) => {
    const jobs = await getjobsList(accountId);
    const parsedJobs = jobs.map(({ id, position }) => ({ value: id, label: position }));
    setMyJobs(parsedJobs);
    setLoading(false);
  };

  useEffect(() => {
    const accountId = window.localStorage.getItem("account_id");
    fetchJobs(accountId);
  }, []);

  const onSubmit = async ({ reason, job }) => {
    setSubmitting(true);
    try {
      const payload = {
        job: job.value,
        reason,
      }
      console.log(payload);
      reset();
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Disputes</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                {loading ? (
                  <div className="row align-items-center">
                    <div className="col-12 mb-11">
                      <Skeleton width={500} height={44} />
                    </div>
                    <div className="col-12">
                      <Skeleton width={500} height={600} />
                    </div>
                  </div>
                ) : (
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="job"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Select Job
                          </label>
                          <Controller
                            name="job"
                            control={control}
                            defaultValue=""
                            rules={validation.job}
                            as={<Select
                              options={myJobs}
                              name="job"
                              className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
                            />}
                          />
                          <span className="text-danger small">{errors?.job?.message || ''}</span>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="reason"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Reason
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter reason"
                            id="reason"
                            name="reason"
                            rows={10}
                            ref={register(validation.reason)}
                          />
                          <span className="text-danger small">{errors?.reason?.message || ''}</span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-8">
                      <div className="row align-items-center">
                        <div className="col-12 col-sm-6">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium rounded-5 text-uppercase w-100"
                            disabled={submitting ? true : false}
                          >
                            {submitting ? "Submitting..." : "Raise"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div >
          </div >
        </div >
      </PageWrapper >
    </>
  );
};

export default DashboardHelp;
